
import { defineComponent } from "vue";
import { popupStore } from "@/stores/PopupStore";
import { mapActions, mapState } from "pinia";import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import AfterConfirmationAcceptanceScreen
  from "@/components/tabs/confirmation-tab/AfterConfirmationAcceptanceScreen.vue";

export default defineComponent({
  name: "PaymentConfirmationTab",
  components: {
    AfterConfirmationAcceptanceScreen,
    LoadingSpinnerWithBackground,
  },
  data() {
    return {
      currentPageName: 'InsuranceWebUAPCPaymentConfirmation',
      isLoading: false,
    }
  },
  computed: {
    ...mapState(confirmationStore, ["policyInfo"]),
    insuredPersonEmail(): string {
      return this.policyInfo.insuredPerson.email;
    }
  },
  mounted() {
    try {
      this.checkStoreToLoadPage();
    } catch (e) {
      this.isLoading = true;
      const popupBox = popupStore();
      if (e instanceof Error) {
        popupBox.showErrorMsg(e.message);
      }
    }
  },
  methods: {
    ...mapActions(confirmationStore, ['arePreviousPagesPartiallyCompleted', 'checkConfirmationPageStatus']),
    checkStoreToLoadPage() {
      if (this.checkConfirmationPageStatus(this.currentPageName)) {
        throw new Error('Payment is already confirmed!');
      } else if (this.arePreviousPagesPartiallyCompleted(this.currentPageName)) {
        throw new Error('Please complete the previous steps.');
      }
    }
  }
});
