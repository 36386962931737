
import CoverAmountCard from "@/components/cover-amount-card/CoverAmountCard.vue";
import QuoteCard from "@/components/quote-card/QuoteCard.vue";
import ResultSuccess from "@/components/result-information/ResultSuccess.vue";
import ResultCannotContinue from "@/components/result-information/ResultCannotContinue.vue";
import ResultReferred from "@/components/result-information/ResultReferred.vue";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { PropType, defineComponent } from "vue";
import ProductQuoteDataGenerator from "@/data-generator/ProductQuoteDataGenerator";
import { insuranceStore } from "@/stores/InsuranceStore";
import { routerStore } from "@/stores/RouterStore";
import { popupStore } from "@/stores/PopupStore";
import { InsuranceCover } from "@/interfaces/productQuote/InsuranceCover";
import { CalculatedBenefitPremium } from "@/interfaces/productQuote/CalculatedBenefitPremium";
import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import { PolicyBenefitStatusRequest } from "@/interfaces/requests/PolicyBenefitStatusRequest"
import InsuranceClient from "@/rest-client/InsuranceClient";
import { mapActions, mapState } from "pinia";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";
import { ProductName } from "@/enums/ProductName.enum";

export default defineComponent({
  name: "ProductQuoteTab",
  components: {
    ResultSuccess,
    ResultCannotContinue,
    ResultReferred,
    QuoteCard,
    CoverAmountCard,
    LoadingSpinnerWithBackground
  },
  props: {
    screenWidth: {type: String as PropType<ScreenWidth>, required: true}
  },
  data() {
    const insuranceCovers: InsuranceCover[] = [];
    const productQuoteTexts: { title: string; content: string }[] = [];
    productQuoteTexts.push(ProductQuoteDataGenerator.financialStrengthText);
    return {
      showPopup: false,
      insuranceCovers,
      productQuoteTexts: productQuoteTexts,
      isPolicyApproved: false,
      withAmendments: false,
      isSubmitted: false,
      isLoading: true,
      isPolicyReferred: false,
      productsQuestionAnswers: null as any,
      productScheduleData: null as any,
      validationError: "",
      sideCoversMax: 500000,
      showQuoteCardNotes: false,
    };
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
    coverAmounts(): { Life: number, Trauma: number, TPD: number } {
      return {
        Life: this.insuranceCovers.find((x: InsuranceCover) => x.name == ProductName.CLI.toString())?.coverAmount ?? 0,
        Trauma: this.insuranceCovers.find((x: InsuranceCover) => x.name == ProductName.TCI.toString())?.coverAmount ?? 0,
        TPD: this.insuranceCovers.find((x: InsuranceCover) => x.name == ProductName.TPD.toString())?.coverAmount ?? 0
      };
    },
    maxCombined() {
      return Math.min(this.sideCoversMax, (this.insuranceCovers.find((x: InsuranceCover) => x.benefitCode == "Life Cover")?.coverAmount ?? Infinity))
    },
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    existingBenefits(): PolicyBenefit[] {

      if(this.policyInfo.policy.paymentConfigurations.length === 0) {
        return [];
      }

      return this.policyInfo.policyBenefits
        .filter(benefit => benefit.benefitStatus === BenefitStatus.Accepted && benefit.coverAmount > 0)
        .map(benefit => {
          const yearlyPolicyBenefitPremiums = benefit.policyBenefitPremiums
            // Filter out any PolicyBenefitPremiums with a null benefitRenewalDate - these are one-off pro-rata payments
            .filter((policyBenefitPremium) => !!policyBenefitPremium.benefitRenewalDate);
          const monthlyPolicyBenefitPremiumSum = this.getPremiumAmountTotalByFrequency(yearlyPolicyBenefitPremiums, 'Monthly');
          const yearlyPolicyBenefitPremiumSum = this.getPremiumAmountTotalByFrequency(yearlyPolicyBenefitPremiums);

          return {
            ...benefit,
            monthlyPremiumAmount: monthlyPolicyBenefitPremiumSum,
            yearlyPremiumAmount: yearlyPolicyBenefitPremiumSum
          }
        });
    },
    existingPremiums(): number {
      // Do not use existingPremiums for further calculations as it is already rounded correctly
      if(this.existingBenefits.length <= 0) {
        return 0;
      }

      return this.existingBenefits.reduce((sum, existingBenefit) => sum + (existingBenefit.monthlyPremiumAmount ?? 0), 0);
    },
    isPolicyDeclined(): boolean {
      return !this.isPolicyApproved && !this.isPolicyReferred;
    }
  },
  watch: {
    coverAmounts(newValue: { Life: number, Trauma: number, TPD: number }, oldValue: { Life: number, Trauma: number, TPD: number }) {
      let trauma = this.insuranceCovers.find((x: InsuranceCover) => x.name == ProductName.TCI.toString());
      let tpd = this.insuranceCovers.find((x: InsuranceCover) => x.name == ProductName.TPD.toString());

      this.validationError = "";

      // Remove covers if remove from quote is pressed
      if (this.insuranceCovers.find(x => x.coverAmount <= 0) != undefined) {
        this.insuranceCovers = this.insuranceCovers.filter(x => x.coverAmount > 0)
      }

      // Limit of combined trauma & tpd is the lowest of 500000 and life cover
      // Don't allow user to set values higher, or life lower
      if (newValue.TPD + newValue.Trauma > this.maxCombined) {
        // If page loads trying to set tpd and trauma to higher than allowed, make them both half of max
        if (oldValue.TPD + oldValue.Trauma <= 0) {
          if (trauma != undefined) {
            trauma.coverAmount = Math.min(this.getMaxCoverForNotes(trauma), this.maxCombined/ (newValue.TPD > 0 ? 2 : 1));
          }
          if (tpd != undefined) {
            tpd.coverAmount = Math.min(this.getMaxCoverForNotes(tpd), this.maxCombined/ (newValue.Trauma > 0 ? 2 : 1));
          }
        }

        const traumaText = "Trauma and Critical Illness";
        const tpdText = "Total and Permanent Disability";
        if (newValue.Trauma + newValue.TPD > newValue.Life) {
          let textString = trauma && tpd? traumaText + ', ' + tpdText : trauma? traumaText: tpdText;
          this.validationError = "The cover amount for " + textString +
            " exceeds the limits due to the reduction of the Life cover amount. Please reduce accordingly."
        } else if (newValue.Trauma + newValue.TPD > this.sideCoversMax) {
          this.validationError = "The sum of cover amounts for " + traumaText + ', ' + tpdText + " exceeds the maximum limit of $500,000. Please reduce accordingly."
        }
      }
    }
  },
  async mounted() {
    let dataGenerator = new ProductQuoteDataGenerator();
    let policyData = await dataGenerator.getPolicySchedule();
    let benefitPremiums: CalculatedBenefitPremium[] =
        await dataGenerator.getBenefitPremiums() ?? [] as Array<CalculatedBenefitPremium>;

    // if calculation can not find valid records
    if (benefitPremiums && benefitPremiums.length == 0) {

      this.isPolicyApproved = false;
      if(this.policyInfo.policy.policyType == "0")
      {
        this.isPolicyReferred = true;
      } else if(this.policyInfo.policy.policyType === "1")
      {
        this.isPolicyReferred = false;
      }

      // update benefit status to referred if age out of range
      if(this.isPolicyReferred || this.isPolicyDeclined)
      {
        let benefitsToUpdate = this.policyInfo.policy.policyBenefits.map(pb => {
          return {
            id: pb.id,
            benefitStatus: this.isPolicyReferred ? BenefitStatus.Referred : BenefitStatus.Declined
          };
        });

        if (benefitsToUpdate && benefitsToUpdate.length > 0) {
          let benefitUpdateRequest : PolicyBenefitStatusRequest = {
            policyBenefits: benefitsToUpdate
          };
          await InsuranceClient.updatePolicyBenefitsStatus(benefitUpdateRequest);
        }
      }
      this.isLoading = false;
      return;
    }

    this.isPolicyApproved =
      ProductQuoteDataGenerator.approvedStatusList.includes(
        policyData.policyStatusName
      );
    if (this.isPolicyApproved) {
      let cardOrder = [ProductName.SSLI.toString(), ProductName.CLI.toString(),
       ProductName.TCI.toString(), ProductName.TPD.toString()];
      const currentPolicyBenefits: PolicyBenefit[] | undefined =
        this.policyInfo.policy.policyBenefits;
      this.insuranceCovers = dataGenerator.getInsuranceCovers(
        policyData.products,
        benefitPremiums,
        currentPolicyBenefits,
        this.policyInfo.policy.policyType
      ).sort((x, y) => {
        return cardOrder.indexOf(x.name) - cardOrder.indexOf(y.name);
      });
    }
    this.isPolicyReferred = this.checkPolicyReferred(policyData);
    this.productScheduleData = policyData.products;
    this.productsQuestionAnswers = policyData.questionAnswers.filter((quesAns: any) => {
      return quesAns.externalReferenceId === "Max_life_cover_allowed" || quesAns.externalReferenceId === "Max_CI_cover_allowed"
      || quesAns.externalReferenceId === "Max_TPD_cover_allowed"});

    // After retreiving quirc questions, we can update the max cover amount
    this.insuranceCovers = this.insuranceCovers.map(insuranceCover => {
      return {
        ...insuranceCover,
        coverAmount: this.getMaxCoverForSlider(insuranceCover),
        // Set this to the max used for the notes section, when we display the slider we call getMaxCoverForSlider inline so this value is not used for that
        maxCover: this.getMaxCoverForNotes(insuranceCover)
      }
    });
    this.showQuoteCardNotes = this.policyInfo.policy.policyType === "0";

    this.validateCoverLoadingsAndExclusions(this.insuranceCovers);

    this.isLoading = false;
  },
  methods: {
    ...mapActions(insuranceStore, ['flattenedPolicyBenefitPremiums', 'getPremiumAmountTotalByFrequency']),
    ...mapActions(routerStore, ["getPage"]),
    ...mapActions(popupStore, ['showErrorMsg']),
    checkPolicyReferred(policyData: any) {
      // If policy is approved, return false immediately as no further check is needed
      if(this.isPolicyApproved){
        return false;
      }

      // Determine if the policy needs referral based on status and type
      let isReferred = false;
      if (this.policyInfo.policy.policyType == "1")
      {
        isReferred = ProductQuoteDataGenerator.referredStatusListSSLI.includes(policyData.policyStatusName);
      }
      else
      {
        isReferred = ProductQuoteDataGenerator.referredStatusListCLI.includes(policyData.policyStatusName);
      }

      if (!isReferred) {
        isReferred = this.policyInfo.policy.policyBenefits.some(
          policyBenefit => policyBenefit.quircPolicy.note?.length > 0
        );
      }

      return isReferred;
    },
    validateCoverLoadingsAndExclusions(insuranceCovers: InsuranceCover[]) {
      let isWithLoadings = false;
      let isWithExclusion = false;
      insuranceCovers.forEach((cover: InsuranceCover) => {
        if (cover.loadingPercentage > 0 || cover.loadingAmount > 0) {
          isWithLoadings = true;
        }

        if (cover.isWithExclusion) {
          isWithExclusion = true;
        }
      });

      if (isWithLoadings) {
        this.productQuoteTexts.push(ProductQuoteDataGenerator.loadingText);
        this.withAmendments = true;
      }

      if (isWithExclusion) {
        this.productQuoteTexts.push(ProductQuoteDataGenerator.exclusionText);
        this.withAmendments = true;
      }
    },
    async reCalculateMonthlyCost(cover: InsuranceCover, newValue: number) {
      cover.coverAmount = newValue;
      const request = {
        gender: this.policyInfo.insuredPerson.gender,
        benefitCode: cover.benefitPremium.benefitCode,
        coverAmount: cover.coverAmount
      };
      const benefitPremiumResponse = await InsuranceClient.calculateBenefitPremiums(request);

      cover.monthlyCost = benefitPremiumResponse.body.monthlyPremium;
    },
    async submitPolicyBenefit(insuranceCovers: InsuranceCover[]) {
      if (!this.policyInfo.policy || !insuranceCovers || this.validationError) {
        return;
      }

      this.isLoading = true;

      const policyInfo = this.policyInfo;

      let policyBenefitStatusRequest: PolicyBenefitStatusRequest = { policyBenefits: [] }
      let updatedPolicyBenefits = [] as PolicyBenefit[];

      for(let policyBenefit of policyInfo.policy.policyBenefits) {
        const cover = insuranceCovers.find(x => x.benefitPremium.benefitId == policyBenefit.benefitId);

        if (!cover || cover.coverAmount <= 0) {
          policyBenefitStatusRequest.policyBenefits.push({id: policyBenefit.id, benefitStatus: BenefitStatus.NotTakenUp});
          let benefitRemoveResponse = await InsuranceClient.deletePolicyBenefit(policyBenefit.id);
          if (benefitRemoveResponse.statusCode != 200
            && benefitRemoveResponse.statusCode != 204
            && benefitRemoveResponse.statusCode != 404) {
            this.showErrorMsg("Unable to remove Policy Benefit.");
            throw new Error("Failed to delete. Abort to avoid inconsistency.");
          }
        }
        else
        {
          policyBenefit.coverAmount = cover.coverAmount;
          policyBenefit.benefitId = cover.benefitPremium.benefitId;
          updatedPolicyBenefits.push(policyBenefit);
        }
      }

      let request = {
        policyId: policyInfo.policy.id,
        gender: policyInfo.insuredPerson.gender,
        policyBenefits: updatedPolicyBenefits,
      };
      if (policyBenefitStatusRequest.policyBenefits.length > 0) {
        let policyBenefitStatusResponse = await InsuranceClient.updatePolicyBenefitsStatus(policyBenefitStatusRequest);

        if (policyBenefitStatusResponse.statusCode != 200) {
          this.showErrorMsg("Unable to update Policy Benefit.");
          this.isLoading = false;
          return;
        }
      }

      let response = await InsuranceClient.submitPolicyBenefits(request);
      if (response?.statusCode != 200) {
        this.showErrorMsg("Unable to update Policy Benefit.");
        this.isLoading = false;
        return;
      }
      this.policyInfo.policy.policyBenefits = response.body;
      this.isSubmitted = false;
      this.goToNextPage();
    },
    showPopupBox() {
      const popupBox = popupStore();
      popupBox.popupState = {
        title: "Cancel application?",
        content: "This insurance application for " + this.policyInfo.insuredPerson.firstName + " " +
         this.policyInfo.insuredPerson.lastName + " will be permanently cancelled and you won’t be able to recover it.",
        secondaryText: "No, keep it",
        okay: "Yes, cancel it",
        showPopupBox: true,
        primaryActionBtn: this.redirectToBoosterURL
      };
    },
    async redirectToBoosterURL() {
      let policyBenefitStatusRequest: PolicyBenefitStatusRequest = { policyBenefits: this.policyInfo.policy.policyBenefits.map(
        (policyBenefit : PolicyBenefit) => {
          return {id: policyBenefit.id, benefitStatus: BenefitStatus.NotTakenUp}
        }
      )};
      let policyBenefitStatusResponse = await InsuranceClient.updatePolicyBenefitsStatus(policyBenefitStatusRequest);

      if (policyBenefitStatusResponse.statusCode != 200) {
        this.showErrorMsg("Sorry, we are unable to cancel the application. Please try again or contact Booster.");
        this.isLoading = false;
        return;
      }
      window.location.href = process.env.VUE_APP_ADV_HUB_URL + '?showToast=CancelApplication';
    },
    goToNextPage() {
      const path = this.getPage(1, this.$route.path, this.$route.query);

      if (path) {
        this.$router.push(path);
      }
    },
    canRemove(benefitName: string) {
      if (this.insuranceCovers.filter((x: InsuranceCover) => x.coverAmount > 0).length <= 1) {
        return false;
      }
      if (benefitName == ProductName.CLI.toString()) {
        return this.existingBenefits.filter(benefit => benefit.benefitStatus === BenefitStatus.Accepted && benefit.coverAmount > 0).length > 0;
      }
      return true;
    },
    getQuircLimit(item: InsuranceCover) {
      switch(item.benefitCode) {
        case "Life Cover":
          return this.productsQuestionAnswers.find((x: any) => x.externalReferenceId === "Max_life_cover_allowed")?.answerValue;
        case "Critical Illness Cover":
          return this.productsQuestionAnswers.find((x: any) => x.externalReferenceId === "Max_CI_cover_allowed")?.answerValue;
        case "Disability Cover":
          return this.productsQuestionAnswers.find((x: any) => x.externalReferenceId === "Max_TPD_cover_allowed")?.answerValue;
      }
      // Will only reach here if we could not find any benefit code in Quirc
      return item.benefitPremium.coverMaximumAmount;
    },
    getMaxCoverForNotes(item: InsuranceCover) {
      // This is the Min of Quirc's limit and Booster's limit
      var quircLimit = this.getQuircLimit(item);
      return Math.min(item.benefitPremium.coverMaximumAmount, quircLimit);
    },
    getMaxCoverForSlider(item: InsuranceCover) {
      // This is the Min of Quirc's limit and the applied for amount
      let maxCover = Math.min(
        this.getQuircLimit(item),
        this.productScheduleData.find((x: any) => x.profileName === item.benefitCode)?.sumAssured);
      return maxCover;
    },
    goToManualUnderwritingTab() {
      const nextPath = this.$route.fullPath.replace("ProductAmendments", "ManualUnderwriting");
      this.$router.push(nextPath);
    }
  },
});
