import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9e15f9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white position-absolute loading-background d-flex justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoadingSpinner, {
      class: "loading-spinner-z-index",
      message: _ctx.message
    }, null, 8, ["message"])
  ]))
}