
import { mapState, mapActions } from "pinia";
import { confirmationStore } from '@/stores/ConfirmationUAPCStore';
import { PropType, defineComponent } from "vue";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import { popupStore } from "@/stores/PopupStore";
import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";

export default defineComponent({
  name: 'AcceptancePage',
  props: {
    screenWidth: {type: String as PropType<ScreenWidth>, required: true}
  },
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    ...mapState(confirmationStore, ['policyInfo']),
    isSmallScreen() {
      return [ScreenWidth.Mobile, ScreenWidth.Small].includes(this.screenWidth);
    },
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    isMediumScreen() {
      return this.screenWidth === ScreenWidth.Medium || this.screenWidth === ScreenWidth.Large;
    },
    isLargeScreen() {
      return this.screenWidth === ScreenWidth.Large;
    },
    isWideScreen() {
      return this.screenWidth === ScreenWidth.Wide;
    },
    isXLargeScreen() {
      return this.screenWidth === ScreenWidth.XLarge;
    }
  },
  async mounted() {
    await this.parseUrlParameters(this.$router, this.$route);

    const popupBox = popupStore();

    // Only load the pages if there are valid policy benefits
    // Accepted status is required to display confirmation page after clicking "Accept"
    const validPolicyBenefits = this.policyInfo.policy.policyBenefits
      .filter((benefit: PolicyBenefit) => [
        BenefitStatus.WaitingForConfirmation,
        BenefitStatus.Accepted
      ].includes(benefit.benefitStatus));
    if(validPolicyBenefits.length === 0) {
      popupBox.showErrorMsg("Sorry, this link is no longer valid. Please contact your financial adviser.");
      throw new Error('Sorry, this link is no longer valid. Please contact your financial adviser.');
    }

    this.isMounted = true;
  },
  methods: {
    ...mapActions(confirmationStore, ['parseUrlParameters']),
  }
})
