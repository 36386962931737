
import QuestionnaireAnswerSectionComponent from "@/components/questionnaire-answer-section/QuestionnaireAnswerSection.vue";
import { defineComponent, PropType } from "vue";
import { QuestionnaireAnswerSection } from "@/interfaces/QuestionnaireAnswer";
import { DocumentTypeEnum } from "@/enums/DocumentType.enum";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import { mapActions } from "pinia";

interface DocumentLink {
  linkText: string;
  link?: string;
  fileName: string;
  documentType: string;
}

export default defineComponent({
  name: "AcceptanceOfCoverSection",
  components: {
    QuestionnaireAnswerSectionComponent,
  },
  props: {
    questionnaireAnswers: {type: Array as PropType<Array<QuestionnaireAnswerSection>>, required: true},
    isMobileScreen: {type: Boolean, required: true}
  },
  data() {
    return {
      policyDocumentLink: {
        linkText: "Policy Document",
        fileName: "Booster SmartCover Policy Document BSC001072024.pdf",
        documentType: DocumentTypeEnum.PolicyDocument,
      } as DocumentLink,
      offerLetterLink: {
        linkText: "Offer of Insurance",
        fileName: "OfferLetter.pdf",
        documentType: DocumentTypeEnum.OfferLetter,
      } as DocumentLink,
    }
  },
  async mounted() {
    this.policyDocumentLink.link = await this.getDocumentDownloadLink(this.policyDocumentLink.documentType as DocumentTypeEnum);
    this.offerLetterLink.link = await this.getDocumentDownloadLink(this.offerLetterLink.documentType as DocumentTypeEnum);
  },
  methods: {
    ...mapActions(confirmationStore, ['getDocumentDownloadLink', 'downloadDocumentFile']),
    async downloadFile(documentLink: DocumentLink) {
      if(documentLink.link) {
        await this.downloadDocumentFile(documentLink.link, documentLink.fileName);
      }
    }
  },
})
