
import { defineComponent } from "vue";
import TextInputSmall from '@/components/text-input/TextInputSmall.vue';
import DatePicker from '@/components/date-picker/DatePicker.vue';
import SingleChoiceQuestionItem from '@/components/single-choice-question-Item/SingleChoiceQuestionItem.vue';
import AddressDetails from "@/components/address-details/AddressDetails.vue";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import InsuranceClient from '@/rest-client/InsuranceClient';
import { mapActions, mapState } from 'pinia';
import { insuranceStore } from '@/stores/InsuranceStore';
import { routerStore } from '@/stores/RouterStore';
import { PolicyOwner } from '@/interfaces/policy-details/PolicyOwner';
import { Address } from "@/interfaces/Address";
import { navStore } from '@/stores/NavStore';
import { ScreenWidth } from "@/enums/ScreenWidth.enum";

export default defineComponent({
  name: "PolicyOwnershipTab",
  components: {
    SingleChoiceQuestionItem,
    AddressDetails,
    TextInputSmall,
    DatePicker,
    LoadingSpinnerWithBackground
  },
  props: {
    screenWidth: {type: String, required: true}
  },
  data() {
    return {
      errorMsg: null as string | null,
      policyOwnershipType: {
        text: "Please select your policy ownership",
        options: [{
          text: "I am the sole policy owner",
          value: "onlyInsured"
        }, {
          text: "I am the policy owner and will add another policy owner",
          value: "both"
        }, {
          text: "Someone else will be the sole policy owner",
          value: "onlySomeoneElse"
        }],
        value: null as string | null
      },
      homeAddressOptions: [{
        text: "My home address is in New Zealand",
        value: "nativeAddress"
      }, {
        text: "My home address is overseas",
        value: "overseasAddress"
      }],
      postalAddressOptions: [{
        text: "My postal address is in New Zealand",
        value: "nativeAddress"
      }, {
        text: "My postal address is overseas",
        value: "overseasAddress"
      }],
      homeAddressType: '',
      postalAddressType: '',
      policyOwnerDetails: {} as PolicyOwner,
      isPostalAddressSame: true,
      errors: {} as Record<string, any>,
      previousPath: null as string | null,
      nextPath: null as string | null,
      isLoading: true,
    }
  },
  computed: {
    ...mapState(insuranceStore, ['policyInfo']),
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    dobMaxDate(): Date {
            const today = new Date();
            var maxDate = today.setFullYear(today.getFullYear() - 18);
            return new Date(maxDate);
        }
  },
  watch: {
    isPostalAddressSame(newValue) {
      if (!this.isPostalAddressSame && this.policyOwnerDetails
        && !this.policyOwnerDetails.addresses[1]) {
        this.policyOwnerDetails.addresses[1] = this.getNewAddress();
        this.policyOwnerDetails.addresses[1].isPostal = true;
      }
      return newValue;
    }
  },
  created() {
    if (this.policyInfo.policy.policyOwners.length == 1) {
      // When only the insured person is a policy owner
      if (this.insuredPersonIsZerothIndex()) {
        this.policyOwnershipType.value = "onlyInsured";
      }
      // When only someone else is a policy owner auto fill out the details
      else {
        this.policyOwnershipType.value = "onlySomeoneElse";
        this.policyOwnerDetails = this.policyInfo.policy.policyOwners[0];
      }
    } else if (this.policyInfo.policy.policyOwners.length == 2) {
      // Sets the form to default to the person who isn't the insured person when user has 2 policy owners.
      this.policyOwnershipType.value = "both";
      if (this.insuredPersonIsZerothIndex()) {
        this.policyOwnerDetails = this.policyInfo.policy.policyOwners[1];
      } else {
        this.policyOwnerDetails = this.policyInfo.policy.policyOwners[0];
      }
    }

    let addresses = this.policyOwnerDetails?.addresses;
    if (addresses == undefined) {
      this.policyOwnerDetails = this.getNewPolicyOwner();
    }
    else if (!this.isSameAddressFromSavedData(addresses)) {
      this.isPostalAddressSame = false;
    }

    this.nextPath = this.getPage(1, this.$route.path, this.$route.query);
    this.previousPath = this.getPage(-1, this.$route.path, this.$route.query);

    this.isLoading = false;
  },
  methods: {
    ...mapActions(insuranceStore, ['mapFluentValidationErrors']),
    ...mapActions(routerStore, ['getPage']),
    ...mapActions(navStore, ['updateNavItemCheckedStatus']),
    isSameAddressFromSavedData(addresses: Array<Address> | null) {
      const isSameAddress = addresses && addresses.length == 1
        && addresses[0].isPostal;

      return isSameAddress;
    },
    getInsuredPolicyOwner() {
      var insuredPerson = this.policyInfo.insuredPerson;
      return {
        firstName: insuredPerson.firstName,
        middleName: insuredPerson.middleName,
        lastName: insuredPerson.lastName,
        dateOfBirth: insuredPerson.dateOfBirth,
        workPhone: insuredPerson.workPhone,
        email: insuredPerson.email,
        mobile: insuredPerson.mobile,
        addresses: insuredPerson.addresses,
        id: insuredPerson.customerId,
        isPrimary: true // Set the primary flag to true for onlyInsured and both cases
      };
    },
    getNewPolicyOwner() {
      return  {
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '',
        workPhone: '',
        email: '',
        mobile: '',
        addresses: [this.getNewAddress()]
      };
    },
    getNewAddress() {
      return {
        address1: '',
        address2: '',
        address3: '',
        postCode: '',
        city: '',
        state: '',
        country: '',
        isPostal: false
      };
    },
    parseAddressAndDateOfBirth(policyOwnerDetails: PolicyOwner | null) {
      if (policyOwnerDetails) {
        if (this.homeAddressType === 'nativeAddress') {
          policyOwnerDetails.addresses[0].country = 'New Zealand';
          delete policyOwnerDetails.addresses[0].state;
        }

        if (!this.isPostalAddressSame) {
          if (this.postalAddressType === 'nativeAddress') {
            policyOwnerDetails.addresses[1].country = 'New Zealand';
            delete policyOwnerDetails.addresses[1].state;
          }
        }

        if (this.isPostalAddressSame) {
          policyOwnerDetails.addresses[0].isPostal = true;
          policyOwnerDetails.addresses[0].isActive = true;
          if (policyOwnerDetails.addresses[1]) {
            policyOwnerDetails.addresses.splice(1);
          }
        } else {
          policyOwnerDetails.addresses[0].isPostal = false;
          policyOwnerDetails.addresses[1].isPostal = true;
          policyOwnerDetails.addresses[0].isActive = true;
          policyOwnerDetails.addresses[1].isActive = true;
        }
      }
      // convert DateOfBirth to expected format
      if (policyOwnerDetails?.dateOfBirth) {
        policyOwnerDetails.dateOfBirth = policyOwnerDetails.dateOfBirth.substring(0, 10);
      }

      return policyOwnerDetails;
    },
    set400Errors(mappedErrors: Record<string, any> | undefined) {
      if (!mappedErrors || !mappedErrors['policyOwners[0]']?.customer) {
        return;
      }

      this.errors = mappedErrors['policyOwners[0]'].customer;
    },
    readError(field: string) {
      if (!this.errors) {
        return undefined;
      }
      return this.errors[field];
    },
    validate() {
      if (this.policyOwnershipType.value == null) {
        this.errors['selectPolicyOwner'] = "Answer required";
        return false;
      }

      if (this.policyOwnershipType.value === "onlyInsured") {
        return true;
      }

      if (!this.policyOwnerDetails) {
        if (!this.policyOwnershipType.value) {
          this.errors['selectPolicyOwner'] = "Answer required";
          return false;
        } else {
          this.errors = {};
          return true;
        }
      }

      // remove all the errors before every validation
      this.errors = {};
      this.errorMsg = null;

      if (!this.homeAddressType) {
        this.errors['selectPolicyOwnerHomeAddress'] = "Answer required";
      }

      if (!this.isPostalAddressSame && !this.postalAddressType) {
        this.errors['selectPolicyOwnerPostalAddress'] = "Answer required";
      }

      // validate policy owner details
      let skipPropertyList = ['middleName', 'mobile', 'workPhone', 'address2', 'address3', 'id', 'isActive'];
      let addressSkipList = ['state', 'country'];

      let key: keyof PolicyOwner;
      for(key in this.policyOwnerDetails) {
        if (skipPropertyList.includes(key)) {
          continue;
        }

        let value = this.policyOwnerDetails[key];

        if (typeof value == 'object' && value) {
          let addressObj: any = value;
          for (let i = 0; i < 2; i++) {
            if (this.isPostalAddressSame && i == 1) {
              continue;
            }
            let val = addressObj[i];

            skipPropertyList = skipPropertyList.filter(item => !addressSkipList.includes(item));

            if ((i === 0 && this.homeAddressType === 'nativeAddress') || (i === 1 && this.postalAddressType === 'nativeAddress')) {
              skipPropertyList = skipPropertyList.concat(addressSkipList);
            }

            for (let addr in val) {
              let address = val[addr];
              if (skipPropertyList.includes(addr)) {
                continue;
              }
              if ((typeof address == 'string' || typeof value == 'number') && (!address || address.length <= 0)) {
                if (!this.errors) {
                  this.errors = {};
                }

                if (!this.errors[`${key}[${i}]`]) {
                  this.errors[`${key}[${i}]`] = {};
                }
                this.errors[`${key}[${i}]`][addr] = 'Answer required';
              }
            }
          }
        }

        if (typeof value == 'string' &&
          (!value || value.length <= 0)) {
          if (!this.errors) {
            this.errors = {};
          }

          this.errors[key] = 'Answer required';
        }

        if (typeof value == 'number' &&
          (!value || value <= 0)) {
          if (!this.errors) {
            this.errors = {};
          }

          this.errors[key] = 'Answer required';
        }
      }
      if (!this.policyOwnerDetails.mobile && !this.policyOwnerDetails.workPhone) {
        this.errors['mobile'] = "Either mobile or workphone is required";
        this.errors['workPhone'] = "Either mobile or workphone is required";
      }

      return this.errors && Object.keys(this.errors).length == 0;
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;

      const deletePolicyOwnersResponse = await InsuranceClient.deletePolicyOwners();
      if (deletePolicyOwnersResponse?.statusCode != 200) {
        this.errorMsg = 'Error happened while submitting data.';
        this.isLoading = false;
        return;
      }

      if (this.policyOwnershipType.value === "onlyInsured" || this.policyOwnershipType.value === "both") {
        var policyOwner = this.getInsuredPolicyOwner();
        const updatePolicyOwnerResponse = await InsuranceClient.updatePolicyOwner(policyOwner);
        if (!this.policyOwnerResponseSuccessful(updatePolicyOwnerResponse)) {
          return;
        }
      }

      if (this.policyOwnershipType.value === "both" || this.policyOwnershipType.value === "onlySomeoneElse") {
        const secondaryPolicyOwnerDetails = this.parseAddressAndDateOfBirth(this.policyOwnerDetails);
        if (this.policyOwnershipType.value === "onlySomeoneElse" && secondaryPolicyOwnerDetails) {
          // Set the primary flag to true for onlySomeoneElse case
          secondaryPolicyOwnerDetails.isPrimary = true;
        }
        const updateSecondaryPolicyOwnerResponse = await InsuranceClient.updatePolicyOwner(secondaryPolicyOwnerDetails);
        if (!this.policyOwnerResponseSuccessful(updateSecondaryPolicyOwnerResponse)) {
          return;
        }
      }

      if (this.nextPath) {
        this.updateNavItemCheckedStatus('Policy ownership');
        this.$router.push(this.nextPath);
      }
    },
    policyOwnerResponseSuccessful(response: {statusCode: number; body: any} | undefined) {
      if (response?.statusCode != 200) {
        this.errorMsg = 'Error happened while submitting data.';

        if (response?.statusCode == 400) {
          this.errors = response?.body.errors;
          const mappedErrors = this.mapFluentValidationErrors(response.body?.errors);
          this.set400Errors(mappedErrors);
          this.errorMsg += ' Please check submitted data.';
        }
        this.isLoading = false;
        return false;
      }
      return true;
    },
    insuredPersonIsZerothIndex() {
      return this.policyInfo.insuredPerson.email === this.policyInfo.policy.policyOwners[0].email
    }
  }
})
