import { createRouter, createWebHashHistory, RouteLocation } from "vue-router";
import { insuranceStore } from "./stores/InsuranceStore";
import NotFoundPage from "@/components/pages/not-found-page/NotFoundPage.vue";
import QuestionnairePage from "@/components/pages/questionnaire-page/QuestionnairePage.vue";
import ProductQuoteTab from "@/components/tabs/product-quote-tab/ProductQuoteTab.vue";
import ContactDetailsTab from "@/components/tabs/contact-details-tab/ContactDetailsTab.vue";
import PolicyOwnershipTab from "@/components/tabs/policy-ownership-tab/PolicyOwnershipTab.vue";
import DebitPaymentTab from "@/components/tabs/debit-payment-tab/DebitPaymentTab.vue";
import ConfirmationTab from "@/components/tabs/confirmation-tab/ConfirmationTab.vue";
import CliAcceptancePage from "@/components/pages/acceptance-page/CliAcceptancePage.vue";
import CliDOBVerificationTab from "@/components/tabs/acceptance-tab/CliDOBVerificationTab.vue";
import CliPaymentConfirmationTab from "@/components/tabs/acceptance-tab/CliPaymentConfirmationTab.vue";
import CliCoverAcceptanceTab from "@/components/tabs/acceptance-tab/CliCoverAcceptanceTab.vue";
import ManualUnderwritingTab from "@/components/tabs/manual-underwriting-tab/ManualUnderwritingTab.vue";

const routes = [
  {
    path: "/",
    beforeEnter: [RefreshPolicyInfo],
    component: QuestionnairePage,
  },
  {
    path: "/About_you/:matchPath(.*)*",
    beforeEnter: [RefreshPolicyInfo],
    component: QuestionnairePage,
  },
  {
    path: "/Medical/:matchPath(.*)*",
    beforeEnter: [RefreshPolicyInfo],
    component: QuestionnairePage,
  },
  {
    path: "/Confirmation",
    component: CliAcceptancePage,
    children: [
      {
        path: "DOBVerification/:matchPath(.*)*",
        component: CliDOBVerificationTab,
      },
      {
        path: "CoverAcceptance/:matchPath(.*)*",
        component: CliCoverAcceptanceTab,
      },
      {
        path: "PaymentConfirmation/:matchPath(.*)*",
        component: CliPaymentConfirmationTab,
      },
    ],
  },
  // The refreshPolicyInfo is needed in every step to update the returned info
  // into the policyInfo
  {
    path: "/AfterQuestionnaire",
    component: QuestionnairePage,
    beforeEnter: [RefreshPolicyInfo],
    children: [
      {
        path: "ProductQuote",
        redirect: "/AfterQuestionnaire/ProductQuote/ProductAmendments",
      },
      {
        path: "ProductQuote/ProductAmendments:matchPath(.*)*",
        beforeEnter: [RefreshPolicyInfo],
        component: ProductQuoteTab,
      },
      {
        path: "ProductQuote/ManualUnderwriting:matchPath(.*)*",
        beforeEnter: [RefreshPolicyInfo],
        component: ManualUnderwritingTab,
      },
      {
        path: "ContactDetails/:matchPath(.*)*",
        beforeEnter: [RefreshPolicyInfo],
        component: ContactDetailsTab,
      },
      {
        path: "PolicyOwnership/:matchPath(.*)*",
        beforeEnter: [RefreshPolicyInfo],
        component: PolicyOwnershipTab,
      },
      {
        path: "Payment/:matchPath(.*)*",
        beforeEnter: [RefreshPolicyInfo],
        component: DebitPaymentTab,
      },
      {
        path: "Confirmation/:matchPath(.*)*",
        beforeEnter: [RefreshPolicyInfo],
        component: ConfirmationTab,
      },
    ],
  },
  { path: "/:catchAll(.*)", component: NotFoundPage },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

async function RefreshPolicyInfo(to: RouteLocation) {
  // Load the parameters
  const insurance = insuranceStore();
  await insurance.parseUrlParameters(router, to.path, to.query.policyInfo?.toString());
}
