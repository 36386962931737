
import { defineComponent } from "vue";
import { PaymentMethodEnum } from "@/enums/PaymentMethod.enum";

export default defineComponent({
  name: "PaymentMethodSection",
  props: {
    validationError: {type: String, default: undefined}
  },
  emits: ["onPaymentMethodChange"],
  data() {
    return {
      isDirectDebitAccepted: null as boolean | null,
    }
  },
  watch: {
    isDirectDebitAccepted(isDdAccepted: boolean) {
      this.$emit("onPaymentMethodChange", isDdAccepted ? PaymentMethodEnum.DirectDebit : PaymentMethodEnum.OnlineBanking);
    }
  }
})
