import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afdc1762"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "row justify-content-center app-content ms-0 me-0" }
const _hoisted_3 = { class: "content-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["row", {'px-5': !_ctx.isSmallScreen}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["col-lg-12 col-md-12 col-sm-12 content-col pt-4", {
              'px-4': _ctx.isSmallScreen, 
              'px-0': _ctx.isMediumScreen, 
              'px-4 mx-3': _ctx.isWideScreen,
              'px-5': _ctx.isXLargeScreen || _ctx.isLargeScreen
            }])
          }, [
            (_ctx.policyInfo.policy.id && _ctx.isMounted)
              ? (_openBlock(), _createBlock(_component_router_view, {
                  key: 0,
                  "is-mobile-screen": _ctx.isMobileScreen,
                  "screen-width": _ctx.screenWidth
                }, null, 8, ["is-mobile-screen", "screen-width"]))
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ])
    ])
  ]))
}