import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "debit-payment-confirmation content position-relative"
}
const _hoisted_2 = { class: "item-secondary-title mb-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "mt-4 pb-4" }
const _hoisted_7 = {
  key: 2,
  class: "item-body mb-4"
}
const _hoisted_8 = { class: "row my-4 overflow-x-hidden" }
const _hoisted_9 = {
  key: 0,
  class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 center-btn"
}
const _hoisted_10 = { class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 text-end center-btn" }
const _hoisted_11 = {
  key: 1,
  class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 center-btn"
}
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinnerWithBackground = _resolveComponent("LoadingSpinnerWithBackground")!
  const _component_CliAcceptanceOfCoverSection = _resolveComponent("CliAcceptanceOfCoverSection")!
  const _component_CliDDConfirmationSection = _resolveComponent("CliDDConfirmationSection")!
  const _component_CliPaymentMethodSection = _resolveComponent("CliPaymentMethodSection")!
  const _component_UnacceptedApplicationResult = _resolveComponent("UnacceptedApplicationResult")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.showApplicationNotAcceptedResult)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinnerWithBackground, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.originallyDirectDebitPaymentMethod)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Please read and accept your cover and your Direct debit and terms and conditions"))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, "Please read and accept your cover terms and conditions"))
          ]),
          _createVNode(_component_CliAcceptanceOfCoverSection, {
            "questionnaire-answers": _ctx.questionnaireAnswers,
            "is-mobile-screen": _ctx.isMobileScreen
          }, null, 8, ["questionnaire-answers", "is-mobile-screen"]),
          (_ctx.originallyDirectDebitPaymentMethod)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("hr", {
                  class: _normalizeClass(["hr my-4", _ctx.isMobileScreen ? 'd-none': ''])
                }, null, 2),
                _createVNode(_component_CliDDConfirmationSection, {
                  "is-mobile-screen": _ctx.isMobileScreen,
                  "next-premium-date": _ctx.nextPremiumDate,
                  "first-premium-date": _ctx.firstPremiumDate,
                  "pro-rata-amount": _ctx.proRataAmount
                }, null, 8, ["is-mobile-screen", "next-premium-date", "first-premium-date", "pro-rata-amount"]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_CliPaymentMethodSection, {
                    "validation-error": _ctx.validationError,
                    onOnPaymentMethodChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paymentMethodChangeHandler($event)))
                  }, null, 8, ["validation-error"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, " Please ensure your payment is made within 14 days to avoid any impact on your cover. ")),
          _createElementVNode("hr", {
            class: _normalizeClass(["hr my-4", _ctx.isMobileScreen ? 'd-none': ''])
          }, null, 2),
          _createElementVNode("div", _hoisted_8, [
            (!_ctx.isMobileScreen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("button", {
                    class: "btn btn-booster-secondary float-start",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelApplicationPopup()))
                  }, " I don't accept ")
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-booster-primary float-end",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onAcceptButtonClick()))
              }, " Accept and commence cover ")
            ]),
            (_ctx.isMobileScreen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("button", {
                    class: "btn btn-booster-secondary float-start",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancelApplicationPopup()))
                  }, " I don't accept ")
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showApplicationNotAcceptedResult)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_UnacceptedApplicationResult)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}