import { PolicyBenefitStatusRequest } from "@/interfaces/requests/PolicyBenefitStatusRequest";
import RequestBuilder from "./RequestBuilder";
import { insuranceStore } from "@/stores/InsuranceStore";
import { ConsentAnswer } from "@/interfaces/ConsentAnswer";

export default class InsuranceClient {
  static quircUrl = "questionnaire";
  static insuredPersonUrl = "insuredPerson";
  static policyUrl = "policy";
  static authUrl = "authToken";
  static benefitUrl = "benefit";
  static customerUrl = "customer";
  static consentUrl = "consent";
  static documentUrl = "document";
  static questionnaireAnswersUrl = "questionnaire/answers";

  // First level cache.
  // This is different from the second level cache (in generators)
  // Those can be set at a higher level and replace each other to accelerate
  // For example, profile cache can set section caches as well
  static cache = {
    conditions: {} as Record<string, any>,
    sections: {} as Record<string, any>,
    sectionQuestions: {} as Record<string, any>,
    profile: {} as Record<string, any>,
  } as Record<string, any>;

  static async createOrUpdateClient(answeredQuestions: any | null) {
    const store = insuranceStore();
    const requestBody: Record<string, any> = {
      customerReferenceId: store.policyInfo.insuredPerson.id,
      policyReferenceId: store.policyInfo.policyRefId,
      displayAnswers: true,
    };

    if (answeredQuestions) {
      requestBody.AnsweredQuestions = answeredQuestions;
    }

    return await RequestBuilder.makePutRequest(`${this.quircUrl}/policy`, requestBody);
  }

  static async updateInsurancePolicy(id: string, patchDocument: any) {
    if (!patchDocument) {
      return;
    }

    return await RequestBuilder.makePatchRequest(`${this.policyUrl}/${id}`, patchDocument);
  }

  static async updatePolicyOwner(body: any) {
    if (!body) {
      return;
    }
    return await RequestBuilder.makePostRequest(`${this.policyUrl}/policyOwner`, body);
  }

  static async deletePolicyOwners() {
    return await RequestBuilder.makeDeleteRequest(`${this.policyUrl}/policyOwner`);
  }

  static async getInsurancePolicy() {
    return await RequestBuilder.makeGetRequest(`${this.policyUrl}`);
  }

  static async getInsurancePolicyNumber() {
    return await RequestBuilder.makeGetRequest(`${this.policyUrl}/policynumber`);
  }

  static async updateInsuredPerson(postUpdateInsuredPerson: any) {
    if (!postUpdateInsuredPerson) {
      return;
    }

    return await RequestBuilder.makePostRequest(
      `${this.insuredPersonUrl}/update`,
      postUpdateInsuredPerson
    );
  }

  static async getPolicySchedule() {
    const store = insuranceStore();
    return await RequestBuilder.makeGetRequest(
      `${this.quircUrl}/policyschedule/${store.policyInfo.policyRefId}`
    );
  }

  static async getQuestionsForSection(sectionExtRefId: string) {
    // First level cache.
    // This is different from the second level cache (in generators)
    // Those can be set at a higher level and replace each other to accelerate
    // For example, profile cache can set section caches as well
    if (
      !InsuranceClient.cache.sectionQuestions[sectionExtRefId] ||
      Object.entries(InsuranceClient.cache.sectionQuestions[sectionExtRefId]).length == 0
    ) {
      const store = insuranceStore();

      let url = `${this.quircUrl}/section/${sectionExtRefId}/questions`;
      if (store.versionId) {
        url += `?questionnaireProfileVersionId=${store.versionId}`;
      }

      InsuranceClient.cache.sectionQuestions[sectionExtRefId] = await RequestBuilder.makeGetRequest(
        url
      );
    }

    return InsuranceClient.cache.sectionQuestions[sectionExtRefId];
  }

  static async getSections(sectionExtRefId: string) {
    // First level cache.
    // This is different from the second level cache (in generators)
    // Those can be set at a higher level and replace each other to accelerate
    // For example, profile cache can set section caches as well
    if (
      !InsuranceClient.cache.sections[sectionExtRefId] ||
      Object.entries(InsuranceClient.cache.sections[sectionExtRefId]).length == 0
    ) {
      const store = insuranceStore();

      let url = `${this.quircUrl}/section/${sectionExtRefId}`;
      if (store.versionId) {
        url += `?questionnaireProfileVersionId=${store.versionId}`;
      }

      InsuranceClient.cache.sections[sectionExtRefId] = await RequestBuilder.makeGetRequest(url);
    }

    return InsuranceClient.cache.sections[sectionExtRefId];
  }

  static async getConditions(questionExtRefId: string) {
    // First level cache.
    // This is different from the second level cache (in generators)
    // Those can be set at a higher level and replace each other to accelerate
    // For example, profile cache can set section caches as well
    if (
      !InsuranceClient.cache.conditions[questionExtRefId] ||
      Object.entries(InsuranceClient.cache.conditions[questionExtRefId]).length == 0
    ) {
      InsuranceClient.cache.conditions[questionExtRefId] = await RequestBuilder.makeGetRequest(
        `${this.quircUrl}/conditions/${questionExtRefId}`
      );
    }

    return InsuranceClient.cache.conditions[questionExtRefId];
  }

  static async getQuestionnaireProfile() {
    // First level cache.
    // This is different from the second level cache (in generators)
    // Those can be set at a higher level and replace each other to accelerate
    // For example, profile cache can set section caches as well
    if (
      !InsuranceClient.cache.profile ||
      Object.entries(InsuranceClient.cache.profile).length == 0
    ) {
      const store = insuranceStore();

      let url = `${this.quircUrl}/profile`;
      if (store.versionId) {
        url += `?questionnaireProfileVersionId=${store.versionId}`;
      }

      InsuranceClient.cache.profile = await RequestBuilder.makeGetRequest(url);
    }

    return InsuranceClient.cache.profile;
  }

  static async submitQuestionnaire() {
    const store = insuranceStore();

    return await RequestBuilder.makePostRequest(
      `${this.quircUrl}/submit/${store.policyInfo.policyRefId}`
    );
  }

  static async getToken(policyInfoEncrypted: string) {
    return await RequestBuilder.makePostRequest(`${this.authUrl}`, {
      encodedRequest: policyInfoEncrypted,
    });
  }

  static async getAcceptanceToken(policyInfoEncrypted: string) {
    return await RequestBuilder.makeGetRequest(`${this.authUrl}/acceptance`, {
      encodedRequest: policyInfoEncrypted,
    });
  }

  static async sendAcceptanceEmail() {
    return await RequestBuilder.makePostRequest(`${this.policyUrl}/acceptance`);
  }

  static async calculateBenefitPremiums(request: any) {
    return await RequestBuilder.makeGetRequest(`${this.benefitUrl}/premiums`, request);
  }

  static async deletePolicyBenefit(policyBenefitId: string) {
    return await RequestBuilder.makeDeleteRequest(`${this.policyUrl}/premium/${policyBenefitId}`);
  }

  static async submitPolicyBenefits(request: any) {
    return await RequestBuilder.makePostRequest(`${this.benefitUrl}/policybenefits`, request);
  }

  static async updatePolicyBenefitsStatus(request: PolicyBenefitStatusRequest) {
    return await RequestBuilder.makePutRequest(`${this.benefitUrl}/policybenefits/status`, request);
  }

  static async updateCustomerMemberNumber() {
    return await RequestBuilder.makePostRequest(`${this.customerUrl}/memberNumber`);
  }

  static async createPolicyBenefits(request: any) {
    return await RequestBuilder.makePostRequest(
      `${this.benefitUrl}/policybenefits/create`,
      request
    );
  }

  static async verifyDateOfBirth(dob: string, verificationCode: string) {
    return await RequestBuilder.makePostRequest(`${this.insuredPersonUrl}/verify/dob`, {
      dob,
      verificationCode,
    });
  }

  static async getConsentQuestions(groupName: string) {
    return await RequestBuilder.makeGetRequest(`${this.consentUrl}/group/${groupName}`);
  }

  static async getQuestionnaireAnswers(policyRefId: string) {
    return await RequestBuilder.makeGetRequest(
      `${this.questionnaireAnswersUrl}?policyRefId=${policyRefId}`
    );
  }

  static async submitConsentQuestionAnswers(answers: ConsentAnswer[]) {
    return await RequestBuilder.makePostRequest(`${this.consentUrl}/answer`, {
      consentAnswers: answers,
    });
  }

  static async submitDdQuestionAnswers(answers: ConsentAnswer[]) {
    return await RequestBuilder.makePostRequest(`${this.consentUrl}/ddAnswer`, {
      consentAnswers: answers,
    });
  }

  static async submitManualUnderwriterNote(note: string) {
    return await RequestBuilder.makePutRequest(`${this.quircUrl}/policy/note`, {
      note,
    });
  }

  static async generateDocumentlink(documentType: string, product: string) {
    return await RequestBuilder.makePostRequest(`${this.documentUrl}/link`, {
      documentType,
      product,
    });
  }

  static async getDocumentFromLink(link: string) {
    return await RequestBuilder.makeGetRequest(link);
  }

  static async uploadReferralSupportingDocs(obj: {
    productType: string;
    file: File;
    formData: FormData;
  }) {
    return await RequestBuilder.makePostRequest(`document`, obj.formData.get("file"), {
      headers: {
        "Product-Type": obj.productType,
        "Document-Type": "InsSupportDocs",
        "Content-Type": obj.file.type,
      },
    });
  }

  static async searchAndValidateAddress(address: string) {
    return await RequestBuilder.makeGetRequest(`address/search?terms=` + address + `&top=10`);
  }

  static async getCountriesList() {
    return await RequestBuilder.makeGetRequest(`address/countries`);
  }

  static async cancelPayment(request: any) {
    return await RequestBuilder.makePostRequest(`${this.policyUrl}/cancelpayment`, request);
  }
}
