
import InsuranceClient from "@/rest-client/InsuranceClient";
import SingleChoiceQuestionItem from "@/components/single-choice-question-Item/SingleChoiceQuestionItem.vue";
import OnlineBanking from "@/components/debit-payment/OnlineBanking.vue";
import DirectDebitForm from "@/components/debit-payment/DirectDebitForm.vue";
import DirectDebitTemplate from "@/components/debit-payment/DirectDebitTemplate.vue";
import DebitPaymentConfirmation from "@/components/debit-payment/DebitPaymentConfirmation.vue";
import SelectInput from "@/components/select-input/SelectInput.vue";
import DatePicker from "@/components/date-picker/DatePicker.vue";
import PopupBox from "@/components/popup-box/PopupBox.vue";
import { PropType, defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { Payment } from "@/interfaces/payment/Payment";
import { insuranceStore } from "@/stores/InsuranceStore";
import { routerStore } from "@/stores/RouterStore";
import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import ProductQuoteDataGenerator from "@/data-generator/ProductQuoteDataGenerator";
import { DirectDebit } from "@/interfaces/payment/DirectDebit";
import { PolicyBenefitStatusRequest } from "@/interfaces/requests/PolicyBenefitStatusRequest";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import PaymentDetailsCard from "./PaymentDetailsCard.vue";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";
import RoundingUtility from "@/utils/RoundingUtility";
import DateCalculationUtility from "@/utils/DateCalculationUtility";

export default defineComponent({
  name: "DebitPaymentTab",
  components: {
    SingleChoiceQuestionItem,
    OnlineBanking,
    DirectDebitForm,
    SelectInput,
    DatePicker,
    DirectDebitTemplate,
    PopupBox,
    DebitPaymentConfirmation,
    LoadingSpinnerWithBackground,
    PaymentDetailsCard
  },
  props: {
    screenWidth: { type: String as PropType<ScreenWidth>, required: true }
  },
  data() {
    return {
      errorMsg: "",
      loading: true,
      loadingMessage: '',
      isConfirmationPage: false,
      isPaymentLoading: false,
      frequencies: [
        { value: "Weekly", text: "Weekly" },
        { value: "Fortnightly", text: "Fortnightly" },
        { value: "Monthly", text: "Monthly" },
        { value: "Annually", text: "Yearly" },
      ],
      payment: {} as Payment,
      paymentMethods: [
        { value: "Direct debit", text: "Direct debit" },
        { value: "Online banking", text: "Online banking" },
        { value: "Direct debit form", text: "Direct debit form" },
      ],
      annualAmount: 0,
      clientValidationErrors: [],
      errors: {} as Record<string, string>,
      paymentMethodError: undefined as string | undefined,
      paymentFrequencyError: undefined as string | undefined,
      nextPaymentError: undefined as string | undefined,
      firstPaymentError: undefined as string | undefined,
      previousPath: null as string | null,
      nextPath: null as string | null,
      isTopUp: false,
      proRata: 0,
      policyData: null as any | null,
    };
  },
  computed: {
    ...mapState(insuranceStore, ['policyInfo', 'existingPolicyBenefits', 'isComprehensivePolicyType', 'isSuperSimplePolicyType']),
    ongoingMaxDate(): Date {
      let today = new Date();
      let daysToAdd = 0;
      if (this.payment.frequency == "Weekly") {
        daysToAdd = 7;
      }
      else if (this.payment.frequency == "Fortnightly") {
        daysToAdd = 14;
      }
      else if (this.payment.frequency == "Monthly") {
        daysToAdd = 30;
      }
      else if (this.payment.frequency == "Annually") {
        daysToAdd = 14;
      }
      return new Date(today.setDate(today.getDate() + daysToAdd));
    },
    firstMaxDate(): Date {
      let today = new Date();
      return new Date(today.setDate(today.getDate() + 14));
    },
    showFirstPremiumPicker(): boolean {
      if (this.payment.frequency == undefined || this.policyInfo.policy.nextPremiumDate == undefined) {
        return false;
      }

      const notWithinDateRange = DateCalculationUtility.getDifferenceBetweenTwoDates(new Date().toDateString(), this.policyInfo.policy.nextPremiumDate) > 14;
      const isCorrectFrequency = this.payment.frequency == 'Monthly';
      return isCorrectFrequency && notWithinDateRange;
    },
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    hasProRata(): boolean {
      return this.policyInfo.policy.nextPremiumDate != null
        && new Date(this.policyInfo.policy.nextPremiumDate).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    },
    existingPolicyBenefitPremiums() {
      return this.flattenedPolicyBenefitPremiums(this.existingPolicyBenefits);
    },
    premiumAmount() {
      const policyBenefitPremiums = this.flattenedPolicyBenefitPremiums(this.policyInfo.policy.policyBenefits);
      const premiumAmountSumByFrequency = this.getPremiumAmountTotalByFrequency(policyBenefitPremiums, this.payment.frequency);

      const existingPremiumAmountSumByFrequency = this.getPremiumAmountTotalByFrequency(this.existingPolicyBenefitPremiums, this.payment.frequency);

      return premiumAmountSumByFrequency + existingPremiumAmountSumByFrequency;
    },
    shouldShowPaymentDetailsCard(): boolean {
      return this.showPaymentDetailsCard(
        this.payment.frequency,
        this.premiumAmount,
        this.policyInfo.policy.policyType,
        this.policyInfo.policy.firstPremiumDate,
        this.policyInfo.policy.nextPremiumDate
      );
    },
    nextDayDate(): Date {
      return DateCalculationUtility.getNextDayDate();
    },
    isNextPaymentDateValid(): boolean {
      if (!this.policyInfo.policy.nextPremiumDate) {
        // If the payment date is empty, it is technically valid and shouldn't show an error
        return true;
      }

      const nextDayDateString = DateCalculationUtility.getNextDayDate().toString();
      const daysDifference = DateCalculationUtility.getDifferenceBetweenTwoDates(nextDayDateString, this.policyInfo.policy.nextPremiumDate);

      return daysDifference >= 0;
    },
    isPolicyReferred(): boolean {
      if (this.isQuircPolicyStatusReferred) {
        return true;
      } // Else: Policy Status is NOT referred, continue below

      // Determine if policy is a 'forced' referral - Quirc approved, but clicked manual refer in Product & Quote page
      if (this.isBenefitStatusReferred) {
        return true;
      }

      // After checking benefit statuses, check if the policy status is approved
      if (this.isQuircPolicyStatusApproved) {
        return false;
      }

      // Final check for manual referral if not already referred by status
      // TODO: this is possibly duplicated functionality with this.isBenefitStatusReferred - revisit in the future
      return this.doesQuircPolicyHaveManualUnderwriterNotes;
    },
    isQuircPolicyStatusReferred(): boolean {
      // Determine if the policy is referred based on status and type
      if (this.isSuperSimplePolicyType) {
        return ProductQuoteDataGenerator.referredStatusListSSLI.includes(
          this.policyData.policyStatusName
        );
      }

      return ProductQuoteDataGenerator.referredStatusListCLI.includes(
        this.policyData.policyStatusName
      );
    },
    isBenefitStatusReferred(): boolean {
      return this.policyInfo.policy.policyBenefits.some(policyBenefit => policyBenefit.benefitStatus === BenefitStatus.Referred);
    },
    isQuircPolicyStatusApproved(): boolean {
      return ProductQuoteDataGenerator.approvedStatusList.includes(
        this.policyData.policyStatusName
      );
    },
    doesQuircPolicyHaveManualUnderwriterNotes(): boolean {
      return this.policyInfo.policy.policyBenefits.some((policyBenefit) => policyBenefit.quircPolicy?.note?.length > 0);
    }
  },
  watch: {
    async 'payment.frequency'() {
      this.proRata = await this.calculateProRata();

      // Reset error message whenever the frequency is changed
      this.nextPaymentError = undefined;

      // If policy is referred, next premium date is possibly in the past and should be validated again
      if (this.isComprehensivePolicyType) {
        this.validatePaymentDate();
      }
    },
    async 'policyInfo.policy.nextPremiumDate'(val: string) {
      if (this.policyInfo.policy.nextPremiumDate) {
        this.nextPaymentError = undefined;
      }
      // When first premium is not explictly selected, next premium date is the first premium date
      // Clear any validation errors for the first payment date
      if (!this.showFirstPremiumPicker) {
        this.policyInfo.policy.firstPremiumDate = val;
      } else {
        this.firstPaymentError = undefined;
      }

      // If policy is referred, next premium date is possibly in the past and should be validated again
      if (this.isComprehensivePolicyType) {
        this.validatePaymentDate();
      }

      this.proRata = await this.calculateProRata();
    },
    async 'policyInfo.policy.firstPremiumDate'() {
      if (this.policyInfo.policy.firstPremiumDate) {
        this.firstPaymentError = undefined;
      }
    },
    showFirstPremiumPicker(val: boolean) {
      if (val) {
        this.policyInfo.policy.firstPremiumDate = "";
      }
      else if (!val) {
        this.policyInfo.policy.firstPremiumDate = this.policyInfo.policy.nextPremiumDate;
      }
    }
  },
  async mounted() {
    let dataGenerator = new ProductQuoteDataGenerator();
    this.policyData = await dataGenerator.getPolicySchedule();

    // Validate next payment date for referred policy as it may be in the past
    if (this.isComprehensivePolicyType) {
      this.validatePaymentDate();
    }
  },
  created() {
    this.payment =
      this.policyInfo.policy.paymentConfigurations &&
        this.policyInfo.policy.paymentConfigurations.length > 0
        ? this.policyInfo.policy.paymentConfigurations[0]
        : ({} as Payment);

    this.payment.policyId = this.policyInfo.policy.id;

    let amount = 0;

    this.policyInfo.policy.policyBenefits.forEach(
      (policyBenefit: PolicyBenefit) => {
        if (
          !policyBenefit.policyBenefitPremiums ||
          policyBenefit.policyBenefitPremiums.length <= 0
        ) {
          return;
        }

        amount =
          amount +
          policyBenefit.policyBenefitPremiums
            .map((a: { premiumAmount: number }) => RoundingUtility.round(a.premiumAmount))
            .reduce((a: number, b: number) => {
              return a + b;
            }) *
          12;
      },
    );
    this.annualAmount = amount;

    // Get existing premium as a monthly payment
    // TODO: does this need to be in the initial function or can it just be a calculated function?
    if (this.existingPolicyBenefits && this.existingPolicyBenefits.length > 0) {
      this.isTopUp = this.getPremiumAmountTotalByFrequency(this.existingPolicyBenefitPremiums, 'Monthly') > 0;
    }

    this.nextPath = this.getPage(1, this.$route.path, this.$route.query);
    this.previousPath = this.getPage(-1, this.$route.path, this.$route.query);

    this.loading = false;
  },
  methods: {
    ...mapActions(insuranceStore, [
      'flattenedPolicyBenefitPremiums',
      'getPremiumAmountTotalByFrequency',
      'mapFluentValidationErrors',
      'showPaymentDetailsCard'
    ]),
    ...mapActions(routerStore, ['getPage']),
    async submitPolicy() {
      if (!this.validatePayment()) {
        return;
      }

      if (this.loading || this.policyInfo.policy == undefined) {
        return;
      }

      this.loading = true;
      if (this.isSuperSimplePolicyType) {
        this.loadingMessage = 'We are creating your policy <br/> It will only take a few seconds';
      }

      this.policyInfo.policy.paymentConfigurations = [this.payment];

      if (this.payment.method != "Direct debit") {
        this.payment.directDebit = null;
      } else if (this.payment.directDebit) {
        const insuredPerson = this.policyInfo.insuredPerson;
        this.payment.directDebit.email = insuredPerson.email;
        this.payment.directDebit.contactNumber =
          insuredPerson.mobile ?? insuredPerson?.workPhone ?? "";
        this.payment.directDebit.fullName = insuredPerson.middleName
          ? `${insuredPerson.firstName} ${insuredPerson.middleName} ${insuredPerson.lastName}`
          : `${insuredPerson.firstName} ${insuredPerson.lastName}`;
      }

      let policyDocument = this.toJsonPatchDocument(this.policyInfo.policy);
      let response = await InsuranceClient.updateInsurancePolicy(
        this.policyInfo.policy.id,
        policyDocument,
      );
      if (response?.statusCode != 200) {
        if (response?.statusCode == 400) {
          const mappedErrors = this.mapFluentValidationErrors(response.body?.errors);
          this.set400Errors(mappedErrors);
          if (this.showBlueBannerError()) {
            this.errorMsg = "Error happened while submitting data. Please check submitted data.";
          }
          // SLI - Display the previous page (enter debit details) if there are any api validation errors
          this.isConfirmationPage = false;
        } else if (this.policyInfo.policy.paymentConfigurations[0].method == "Direct debit") {
          this.errorMsg = "Error happened while submitting data. Please check submitted data.";
        }
        this.hideLoading();
        return;
      }

      // For referred cases, go to the end page directly
      if (!this.policyInfo.policyBenefits.find(pb => pb.benefitStatus == BenefitStatus.Initialized)) {
        this.loading = false;

        if (this.nextPath) {
          this.$router.push(this.nextPath);
        }
        return;
      }

      // If has pro rata, create seperate premiums for them
      if (this.hasProRata) {
        let policyBenefitRequest = {
          gender: this.policyInfo.insuredPerson.gender,
          isSmoker: this.policyInfo.insuredPerson.smokerStatus === "Smoker",
          policyBenefits: this.policyInfo.policy.policyBenefits,
          nextPremiumDate: this.policyInfo.policy.nextPremiumDate
        };
        let response = await InsuranceClient.submitPolicyBenefits(policyBenefitRequest);
        if (response?.statusCode != 200) {
          this.errorMsg = "Unable to update Policy Benefit.";
          this.hideLoading();
          return;
        }
      }

      // If user is applying for comprehensive we have to wait for them to confirm
      // An email will be sent with a link that the user needs to click
      if (this.isComprehensivePolicyType && !this.isPolicyReferred) {
        let acceptanceEmailResponse = await InsuranceClient.sendAcceptanceEmail();

        if (acceptanceEmailResponse.statusCode != 200) {
          this.errorMsg = "Error happened while submitting data.";
          this.hideLoading();
          return;
        }

        let policyBenefitStatusRequest = {} as PolicyBenefitStatusRequest;
        policyBenefitStatusRequest.policyBenefits = this.policyInfo.policy.policyBenefits
          .filter((x) => x.benefitStatus == BenefitStatus.Initialized)
          .map((policyBenefit: PolicyBenefit) => {
            return {
              id: policyBenefit.id,
              benefitStatus: BenefitStatus.WaitingForConfirmation,
            };
          });

        if (policyBenefitStatusRequest.policyBenefits.length > 0) {
          let policyBenefitStatusResponse = await InsuranceClient.updatePolicyBenefitsStatus(
            policyBenefitStatusRequest
          );

          if (policyBenefitStatusResponse.statusCode != 200) {
            this.errorMsg = "Error happened while submitting data.";
            this.hideLoading();
            return;
          }
        }
      }

      if (this.isSuperSimplePolicyType) {
        let memberNumberResponse = await InsuranceClient.updateCustomerMemberNumber();
        if (memberNumberResponse.statusCode != 200) {
          this.errorMsg = "Error happened while submitting data.";
          this.hideLoading();
          return;
        }
      }

      if (this.nextPath) {
        this.$router.push(this.nextPath);
      }
    },
    hideLoading() {
      this.loading = false;
      this.loadingMessage = '';
    },
    toJsonPatchDocument(policyDocument: InsurancePolicy) {
      let patchDocument = [];
      patchDocument.push({
        op: "replace",
        path: "/paymentConfigurations",
        value: policyDocument.paymentConfigurations,
      });
      patchDocument.push({
        op: "replace",
        path: "/nextPremiumDate",
        value: policyDocument.nextPremiumDate,
      });
      patchDocument.push({
        op: "replace",
        path: "/firstPremiumDate",
        value: policyDocument.firstPremiumDate,
      });
      return patchDocument;
    },
    toNextPage() {
      if (this.payment.method == "Direct debit") {
        if (!this.validatePayment()) {
          return;
        }
        if (this.isSuperSimplePolicyType) {
          this.isConfirmationPage = true;
          // Reset the error messages
          this.errorMsg = "";
        } else {
          this.submitPolicy();
        }
      } else {
        this.submitPolicy();
      }
    },
    set400Errors(mappedErrors: Record<string, any> | undefined) {
      if (!mappedErrors || !mappedErrors["paymentConfigurations[0]"]) {
        return;
      }

      const paymentErrors = mappedErrors["paymentConfigurations[0]"];
      this.paymentFrequencyError = mappedErrors["frequency"];
      this.paymentMethodError = paymentErrors["method"];
      this.errors = paymentErrors["directDebit"];
    },
    validatePayment() {
      let valid = true;
      this.paymentMethodError = undefined;
      this.paymentFrequencyError = undefined;
      this.nextPaymentError = undefined;
      this.firstPaymentError = undefined;
      this.errors = {};

      if (this.isComprehensivePolicyType) {
        this.validatePaymentDate();
        valid = this.nextPaymentError === undefined;
      }

      if (this.payment.method == null) {
        this.paymentMethodError = "Answer required";
        valid = false;
      }

      if (this.payment.frequency == null) {
        this.paymentFrequencyError = "Answer required";
        valid = false;
      }

      if (this.payment.method == "Direct debit") {
        if (!this.payment.directDebit) {
          this.payment.directDebit = (
            this.$refs.directDebitTemplateComponent as any
          ).getNewDirectDebit();
        }

        let skipKeyList = ["id", "policyMemberNumber", "fullName", "email", "contactNumber"];

        let key: keyof DirectDebit;
        for (key in this.payment.directDebit) {
          if (skipKeyList.includes(key)) {
            continue;
          }

          let value = this.payment.directDebit![key];

          if (!value || value.length <= 0) {
            this.errors[key] = "Answer required";
            valid = false;
          }

          if (key === "bankAccountNumber" && value) {
            if (/^\d{2}-\d{4}-\d{7}-\d{2}$/.test(value)) {
              this.errors[key] = "Answer requires 3 digits in suffix";
              valid = false;
            } else if (!/^\d{2}-\d{4}-\d{7}-\d{3}$/.test(value)) {
              this.errors[key] = "Answer is not in the correct format";
              valid = false;
            }
          }

          if (key === "email" && value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
              this.errors[key] = "Invalid email address";
              valid = false;
            }
          }
        }
      }

      // Validation errors
      var policy = this.policyInfo.policy;

      if (policy.nextPremiumDate == null) {
        this.nextPaymentError = "Select a valid date for your payments frequency";
        valid = false;
      }

      if (this.showFirstPremiumPicker && !policy.firstPremiumDate) {
        this.firstPaymentError = "Select a valid date for your payments frequency";
        valid = false;
      }

      return valid;
    },
    showBlueBannerError() {
      if (this.policyInfo.policy.paymentConfigurations[0].method == "Direct debit") {
        // Check if there are any errors other than 'bankAccountNumber'
        var otherErrors = Object.keys(this.errors).filter((key) => key !== "bankAccountNumber");

        return (
          (this.paymentFrequencyError !== undefined || this.paymentMethodError !== undefined) &&
          otherErrors.length > 0
        );
      }
      return true;
    },
    getNextDayDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      return tomorrow;
    },
    getFullName() {
      return this.policyInfo.insuredPerson.middleName ?
        `${this.policyInfo.insuredPerson.firstName} ${this.policyInfo.insuredPerson.middleName} ${this.policyInfo.insuredPerson.lastName}` :
        `${this.policyInfo.insuredPerson.firstName} ${this.policyInfo.insuredPerson.lastName}`;
    },
    async calculateProRata() {
      this.isPaymentLoading = true;
      var proRata = 0;
      try {
        if (this.hasProRata) {
          for (let policyBenefit of this.policyInfo.policy.policyBenefits) {
            const request = {
              gender: this.policyInfo.insuredPerson.gender,
              benefitCode: policyBenefit.benefit.benefitCode,
              coverAmount: policyBenefit.coverAmount,
              nextPremiumDate: this.policyInfo.policy.nextPremiumDate
            };
            const benefitPremiumResponse = await InsuranceClient.calculateBenefitPremiums(request);
            proRata += RoundingUtility.round(benefitPremiumResponse.body.monthlyPremium);
          }
        }
        if(this.isTopUp){
          // Add on any unpaid pro rata from existing benefits
          for (let policyBenefit of this.existingPolicyBenefits.filter(pb => pb.proRataPaid === false)) {
            const request = {
              gender: this.policyInfo.insuredPerson.gender,
              benefitCode: policyBenefit.benefit.benefitCode,
              coverAmount: policyBenefit.coverAmount,
              nextPremiumDate: this.policyInfo.policy.nextPremiumDate
            };
            const benefitPremiumResponse = await InsuranceClient.calculateBenefitPremiums(request);
            proRata += RoundingUtility.round(benefitPremiumResponse.body.monthlyPremium);
          }
        }
      } finally {
        this.isPaymentLoading = false;
      }
      return RoundingUtility.round(proRata);
    },
    validatePaymentDate() {
      if (this.isNextPaymentDateValid) {
        this.nextPaymentError = undefined;
        return;
      }

      this.nextPaymentError = "Please select new payment dates";
    }
  },
});
