

import { defineComponent } from "vue";
import PaymentDetailsCard from "@/components/tabs/debit-payment-tab/PaymentDetailsCard.vue";
import ConditionsOfThisAuthority
  from "@/components/terms-and-conditions/conditions-of-this-authority/ConditionsOfThisAuthority.vue";
import SummaryCard from "@/components/summary-card/SummaryCard.vue";
import SpecificConditions from "@/components/terms-and-conditions/specific-conditions/SpecificConditions.vue";
import { SingleChoiceQuestion } from "@/interfaces/singlechoicequestionitem/SingleChoiceQuestion";
import { Payment } from "@/interfaces/payment/Payment";
import { mapActions, mapState } from "pinia";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import { PolicyBenefitPremium } from "@/interfaces/payment/PolicyBenefit";
import { insuranceStore } from "@/stores/InsuranceStore";

export default defineComponent({
  components: {
    SpecificConditions,
    SummaryCard,
    ConditionsOfThisAuthority,
    PaymentDetailsCard
  },
  props: {
    isMobileScreen: {type: Boolean, required: true},
    nextPremiumDate: {type: String, default: undefined},
    firstPremiumDate: {type: String, default: undefined},
    proRataAmount: {type: Number, default: undefined},
  },
  computed: {
    ...mapState(confirmationStore, ['policyInfo']),
    paymentConfiguration(): Payment | undefined {
      return this.policyInfo.policy.paymentConfigurations[0];
    },
    directDebitSummaryItems(): SingleChoiceQuestion[] {
      return [
        {
          text: "Bank name",
          value: this.paymentConfiguration?.directDebit?.bankName ?? "",
        },
        {
          text: "Bank account number",
          value: this.paymentConfiguration?.directDebit?.bankAccountNumber
            ? this.obfuscateBankAccountNumber(this.paymentConfiguration?.directDebit?.bankAccountNumber)
            : "",
        },
        {
          text: "Bank account name",
          value: this.paymentConfiguration?.directDebit?.bankAccountName ?? "",
        }
      ];
    },
    premiumAmount(): number {
      // Filter for premiums that include renewal date - if date is undefined it is a pro-rata premium
      const policyBenefitPremiumsExcludingProRata = this.flattenedPolicyBenefitPremiums(this.policyInfo.policy.policyBenefits)
        .filter((policyBenefitPremium: PolicyBenefitPremium) => !!policyBenefitPremium.benefitRenewalDate);

      // Sum the premium amount from all valid policy benefits
      return policyBenefitPremiumsExcludingProRata.reduce(
        (total: number, current: PolicyBenefitPremium) => total + current.premiumAmount, 0);
    },
    shouldShowPaymentDetailsCard(): boolean {
      const payment = this.policyInfo?.policy?.paymentConfigurations[0];

      return this.showPaymentDetailsCard(
        payment.frequency,
        this.premiumAmount,
        this.policyInfo.policy.policyType,
        this.policyInfo.policy.firstPremiumDate,
        this.policyInfo.policy.nextPremiumDate
      );
    },
    benefitRenewalDate(): string {
      return this.policyInfo.policy.policyBenefits[0]?.policyBenefitPremiums[0]?.benefitRenewalDate;
    },
    frequency(): string {
      return this.policyInfo.policy.paymentConfigurations[0]?.frequency;
    }
  },
  methods: {
    ...mapActions(insuranceStore, [
      'flattenedPolicyBenefitPremiums',
      'showPaymentDetailsCard'
    ]),
    obfuscateBankAccountNumber(bankAccountNumber: string): string {
      // TODO: can be made static and moved into a utility class
      // Only show the last 7 digits
      // Validate that the string is in the proper bank account format, otherwise just return it
      if (!(/^\d{2}-\d{4}-\d{7}-\d{3}$/.test(bankAccountNumber))) {
        return bankAccountNumber;
      }

      const firstDigits = bankAccountNumber.substring(0, bankAccountNumber.length - 8);
      const lastSevenDigits = bankAccountNumber.substring(bankAccountNumber.length - 8);

      return `${firstDigits.replaceAll(/\d/g, "X")}${lastSevenDigits}`;
    },
  }
});
