/// Used for date calculations
export default class DateCalculationUtility {
  /* The result is the number of days between date 1 and date 2. A negative number indicates that
   * the second date is before the first date (in the past), whereas a positive number means that the second date
   * is after the first date (in the future).
   */
  static getDifferenceBetweenTwoDates(dateString1: string, dateString2: string): number {
    const date1 = new Date(new Date(dateString1).setHours(0, 0, 0, 0));
    const date2 = new Date(new Date(dateString2).setHours(0, 0, 0, 0));
    const differenceInTime = date2.getTime() - date1.getTime();
    const millisecondToDayMultiplier = 1000 * 3600 * 24;

    return differenceInTime / millisecondToDayMultiplier;
  }

  // Gets the date one day (24 hours) after the input date.
  static getNextDayDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  }
}
