
import { PropType, defineComponent } from "vue";
import PaymentDetailsCard from "../tabs/debit-payment-tab/PaymentDetailsCard.vue";
import ConditionsOfThisAuthority from "@/components/terms-and-conditions/conditions-of-this-authority/ConditionsOfThisAuthority.vue";
import SpecificConditions from "@/components/terms-and-conditions/specific-conditions/SpecificConditions.vue";
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { Payment } from "@/interfaces/payment/Payment";
import { PolicyBenefitPremium } from "@/interfaces/payment/PolicyBenefit";
import { mapActions } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore";
import SummaryCard from "@/components/summary-card/SummaryCard.vue";
import { SingleChoiceQuestion } from "@/interfaces/singlechoicequestionitem/SingleChoiceQuestion";

export default defineComponent({
  name: "DebitPaymentConfirmation",
  components: {
    ConditionsOfThisAuthority,
    PaymentDetailsCard,
    SpecificConditions,
    SummaryCard,
  },
  props: {
    policy: { type: Object as PropType<InsurancePolicy>, required: true },
    payment: { type: Object as PropType<Payment>, required: true },
    existingPremiums: { type: Array as PropType<PolicyBenefitPremium[]>, default: () => [] },
    isMobileScreen: {type: Boolean, default: false},
    proRata: { type: Number, default: 0 },
  },
  data() {
    return {
      directDebitItems: [] as SingleChoiceQuestion[],
    };
  },
  computed: {
    existingPremiumSum(): number {
      return this.getPremiumAmountTotalByFrequency(this.existingPremiums, this.payment.frequency);
    },
    premiumAmount(): number {
      const premiumBenefitsFlattened = this.flattenedPolicyBenefitPremiums(
        this.policy.policyBenefits
      );
      const premiumTotalByFrequency = this.getPremiumAmountTotalByFrequency(
        premiumBenefitsFlattened,
        this.payment.frequency
      );

      return premiumTotalByFrequency + this.existingPremiumSum;
    },
    shouldShowPaymentDetailsCard(): boolean {
      return this.showPaymentDetailsCard(
        this.payment.frequency,
        this.premiumAmount,
        this.policy.policyType,
        this.policy.firstPremiumDate,
        this.policy.nextPremiumDate
      );
    },
    authorityMessage() {
      return this.policy.policyType === "1"
      // SSLI
        ? "If you do not have individual authority over the bank account, please go back to the 'Payment' section and select ‘Online banking’."
      //CLI
        : "If you do not have individual authority over the bank account, please select ‘I want to switch to online banking’ in the ‘Payment method’ section below.";
    },
  },
  mounted() {
    this.directDebitItems = [
      {
        text: "Bank name",
        value: this.payment.directDebit?.bankName ?? "",
      },
      {
        text: "Bank account number",
        value: this.payment.directDebit?.bankAccountNumber ?? "",
      },
      {
        text: "Bank account name",
        value: this.payment.directDebit?.bankAccountName ?? "",
      },
    ];
  },
  methods: {
    ...mapActions(insuranceStore, [
      "flattenedPolicyBenefitPremiums",
      "getPremiumAmountTotalByFrequency",
      "showPaymentDetailsCard",
    ]),
  },
});
