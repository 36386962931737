
import { defineComponent } from "vue";
import InsuranceClient from "@/rest-client/InsuranceClient";
import QuestionnaireAnswerMappingService from "@/services/QuestionnaireAnswerMappingService";
import { popupStore } from "@/stores/PopupStore";
import { mapActions, mapState } from "pinia";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { QuestionnaireAnswerSection } from "@/interfaces/QuestionnaireAnswer";
import { CalculatedBenefitPremium } from "@/interfaces/productQuote/CalculatedBenefitPremium";
import { dataGeneratorConfigs } from "@/data-generator/DataGeneratorConfigs";
import UnacceptedApplicationResult from "@/components/result-information/UnacceptedApplicationResult.vue";
import CliAcceptanceOfCoverSection from "@/components/tabs/acceptance-tab/sections/CliAcceptanceOfCoverSection.vue";
import CliDDConfirmationSection from "@/components/tabs/acceptance-tab/sections/CliDDConfirmationSection.vue";
import CliPaymentMethodSection from "@/components/tabs/acceptance-tab/sections/CliPaymentMethodSection.vue";
import RoundingUtility from "@/utils/RoundingUtility";
import { PaymentMethodEnum } from "@/enums/PaymentMethod.enum";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";

export default defineComponent({
  name: "CoverAcceptanceTab",
  components: {
    CliAcceptanceOfCoverSection,
    CliDDConfirmationSection,
    LoadingSpinnerWithBackground,
    CliPaymentMethodSection,
    UnacceptedApplicationResult,
  },
  props: {
    screenWidth: {type: String, required: true},
    isMobileScreen: {type: Boolean, default: false}
  },
  data() {
    return {
      currentPageName: 'InsuranceWebUAPCAcceptanceCover',
      questionnaireAnswers: [] as QuestionnaireAnswerSection[],
      yourQuoteSection: {} as QuestionnaireAnswerSection,
      validationError: '',
      errorMsg: null as string | null,
      isLoading: false,
      showApplicationNotAcceptedResult: false,
      paymentMethod: null as PaymentMethodEnum | null,
      firstPremiumDate: undefined as string | undefined,
      nextPremiumDate: undefined as string | undefined,
      proRataAmount: undefined as number | undefined,
    }
  },
  computed: {
    ...mapState(confirmationStore, ['policyInfo']),
    originallyDirectDebitPaymentMethod(): boolean {
      return this.policyInfo?.policy?.paymentConfigurations[0]?.method?.toLowerCase() === "Direct Debit".toLowerCase();
    },
  },
  async mounted() {
    try {
      this.checkIfCoverIsAlreadyAccepted();
      this.checkStoreToLoadPage();
    } catch (e) {
      this.isLoading = true;
      const popupBox = popupStore();
      if (e instanceof Error) {
        popupBox.showErrorMsg(e.message);
        return; // Do not proceed if an error message popup is being displayed
      }
    }

    this.isLoading = true;
    await this.loadQuestionnaireAnswers();
    const success = await this.recalculateProRata();
    if(success) {
      this.isLoading = false;
    } else {
      // Leave the isLoading as true to prevent the user from proceeding. Hacky but there are no designs for this scenario yet
      popupStore().showErrorMsg("Failed to calculate premiums. Please try again or contact us.");
    }
  },
  methods: {
    ...mapActions(confirmationStore, [
      'applicationCancel',
      'areAllPreviousPagesNotCompleted',
      'arePreviousPagesPartiallyCompleted',
      'getDobVerificationPageRoute',
      'getNextPage',
      'getQuestionnaireAnswers',
      'getYourQuoteDetails',
      'setConfirmationItemAsChecked'
    ]),
    checkStoreToLoadPage() {
      // TODO: For now leave the config mechanic in, but it can possibly be cleaned up and simplified
      if(this.areAllPreviousPagesNotCompleted(this.currentPageName)) {
        // All the previous page have not been completed, redirect to DOBVerification
        const dobVerificationPageRoute = this.getDobVerificationPageRoute(this.$route.fullPath);
        this.$router.push(dobVerificationPageRoute);
      } else {
        // Else - at least one of the previous pages were completed but not necessarily all
        if(this.arePreviousPagesPartiallyCompleted(this.currentPageName)) {
          throw new Error('Please complete the previous steps.');
        } // Else - all the previous pages have been completed
      }
    },
    checkIfCoverIsAlreadyAccepted() {
      // Check if all policy benefits are status "Accepted", and if so, throw the error

      const waitingForConfirmationBenefits = this.policyInfo.policy.policyBenefits
        // Filter out other statuses (eg Declined, NTU)
        .filter(pb => [BenefitStatus.WaitingForConfirmation, BenefitStatus.Accepted].includes(pb.benefitStatus))
        // Filter the benefit list further to show only WaitingForConfirmation benefits
        .filter(pb => pb.benefitStatus === BenefitStatus.WaitingForConfirmation);

      if(waitingForConfirmationBenefits.length === 0) {
        // There are no benefits that are WaitingForConfirmation, they are all Accepted
        throw new Error('Sorry, this link is no longer valid. Please contact your financial adviser.');
      }
    },
    validate() {
      this.errorMsg = null;
      this.validationError = '';

      // If payment method was DD, ensure that they picked either the DD or Online banking radio buttons
      if(this.originallyDirectDebitPaymentMethod && (this.paymentMethod === null || this.paymentMethod === undefined)) {
        this.validationError = 'Payment method is required';
      }

      return !this.validationError;
    },
    async loadQuestionnaireAnswers() {
      const aboutYouAndMedicalSections = await this.getQuestionnaireAnswers();

      const mappedAboutYouAndMedicalSections = await QuestionnaireAnswerMappingService.mapQuestionnaireAnswers(aboutYouAndMedicalSections, dataGeneratorConfigs);
      this.yourQuoteSection = this.getYourQuoteDetails();

      // Prepend the "Your quote" section
      this.questionnaireAnswers = [this.yourQuoteSection, ...mappedAboutYouAndMedicalSections];
    },
    async onAcceptButtonClick() {
      // Check if Payment method has been switched to online banking
      if(this.originallyDirectDebitPaymentMethod && this.paymentMethod === PaymentMethodEnum.OnlineBanking) {
        return this.changeToOnlineBanking();
      }
      await this.goToNextPage();
    },
    async changeToOnlineBanking() {
      // Validate again in case of any issues, but we don't actually need to do this
      if (!this.validate()) {
        return;
      }

      // We should only get here if the page includes direct debit acceptance, but we'll check anyway in case
      if (this.originallyDirectDebitPaymentMethod) {
        this.isLoading = true;

        // Cancel the direct debit payment configuration
        let cancelDdResponse = await InsuranceClient.cancelPayment({policyId: this.policyInfo.policy.id});

        if(cancelDdResponse?.statusCode !== 200) {
          const popupBox = popupStore();
          popupBox.showErrorMsg("Error occurred while updating Payment method.");
          return;
        }
      }

      // Continue on to policy acceptance submission
      await this.goToNextPage();
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;

      // Update the status of the policy benefits that belong to this policy, and email required documents
      let policyBenefitRequest = {
        gender: this.policyInfo.insuredPerson.gender,
        policyBenefits: this.policyInfo.policy.policyBenefits,
        nextPremiumDate: this.policyInfo.policy.nextPremiumDate,
        recalculateProRata: true
      };
      const policyBenefitSubmitResponse = await InsuranceClient.submitPolicyBenefits(policyBenefitRequest);
      const updateMemberNumberResponse = await InsuranceClient.updateCustomerMemberNumber();

      if (policyBenefitSubmitResponse?.statusCode != 200 || updateMemberNumberResponse?.statusCode != 200) {
        this.errorMsg = 'Error happened while submitting data.';
        if (policyBenefitSubmitResponse?.statusCode == 400 || updateMemberNumberResponse?.statusCode == 400) {
          this.errorMsg += ' Please check submitted data.'
        }

        this.isLoading = false;
        const popupBox = popupStore();
        popupBox.showErrorMsg(this.errorMsg);
        return;
      }

      this.setConfirmationItemAsChecked(this.currentPageName);
      const nextPath = this.getNextPage(this.$route.fullPath);
      if(nextPath) {
        this.$router.push(nextPath);
      }
    },
    cancelApplicationPopup() {
      const popupBox = popupStore();
      popupBox.popupState = {
        title: "I don't accept",
        content: "Are you sure you don't want to accept your cover?",
        secondaryText: "No",
        okay: "Yes",
        showPopupBox: true,
        primaryActionBtn: this.cancelApplication
      };
    },
    async cancelApplication() {
      const response = await this.applicationCancel();
      if (!response) {
        const popupBox = popupStore();
        popupBox.showErrorMsg("Error occurred while cancelling application.");
        return;
      }

      this.showApplicationNotAcceptedResult = true;
    },
    async recalculateProRata() {
      const recalculatedBenefitPremiums = [] as Array<CalculatedBenefitPremium>;
      for (const policyBenefit of this.policyInfo.policy.policyBenefits) {
        if (policyBenefit.benefitStatus != BenefitStatus.WaitingForConfirmation) {
          continue;
        }

        const request = {
          gender: this.policyInfo.insuredPerson.gender,
          benefitCode: policyBenefit.benefit.benefitCode,
          coverAmount: policyBenefit.coverAmount,
          nextPremiumDate: this.policyInfo.policy.nextPremiumDate,
          recalculateProRata: true
        };
        const benefitPremiumResponse = await InsuranceClient.calculateBenefitPremiums(request);
        if (benefitPremiumResponse.statusCode !== 200) {
          if (benefitPremiumResponse.statusCode == 404) {
            continue;
          }

          return false;
        }

        recalculatedBenefitPremiums.push(benefitPremiumResponse.body);
      }

      if(recalculatedBenefitPremiums.length === 0) {
        return false;
      }

      this.firstPremiumDate = recalculatedBenefitPremiums[0].firstPremiumDate;
      this.nextPremiumDate = recalculatedBenefitPremiums[0].nextPremiumDate;
      this.proRataAmount = RoundingUtility.round(recalculatedBenefitPremiums.reduce((total, benefitPremium) => total + RoundingUtility.round(benefitPremium.monthlyPremium), 0));

      // Success
      return true;
    },
    paymentMethodChangeHandler(newPaymentMethod: PaymentMethodEnum) {
      this.paymentMethod = newPaymentMethod;
    }
  }
});
