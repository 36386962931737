
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentDetailsCard",
  props: {
    paymentAmount: { type: Number, required: true },
    proRata: { type: Number, default: 0 },
    firstPremiumDate: {type: String, default: ''},
    nextPremiumDate: {type: String, default: ''},
    benefitRenewalDate: {type: String, default: ''},
    frequency: {type: String, required: true},
    calledFrom: {type: String, default: 'DebitPayment'},
    isLoading: {type: Boolean, default: false}
  },
  computed: {
    formattedFrequency() {
      if (this.frequency == "Annually") {
        return "Yearly";
      }
      return this.frequency;
    },
    hasProRata(): boolean {
      return this.proRata > 0
    },
    isNextPaymentDateAfterRenewalDate(): boolean {
      const benefitRenewal = new Date(this.benefitRenewalDate ?? '');
      const nextPremium = new Date(this.nextPremiumDate ?? '');

      return this.proRata > 0 && nextPremium > benefitRenewal;
    }
  },
  methods: {
    formatToTwoDecimalPlace(dollarAmount: number): string {
      return dollarAmount.toFixed(2);
    },
    formatDateString(dateString: string): string {
      const date = new Date(dateString);
      if(isNaN(date.getDate())) {
        return '';
      }
      return date.toLocaleDateString("nl-NL", { day:'2-digit', month:'2-digit', year:'numeric' });
    }
  }
});
