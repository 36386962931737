
import { defineComponent } from "vue";

export default defineComponent({
  name: 'LoadingSpinner',
  props: {
    message: {
      type: String,
      default: 'Loading...'
    }
  },
  computed: {
    computedMessage() {
      return this.message || 'Loading...';
    }
  }
})
